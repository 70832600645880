import { createGlobalState, useStorage } from '@vueuse/core'

export const useUser = () => {
  return {
    fetchUserData: (id) => {
      const { find } = useStrapi4()
      const user = useStrapiUser()
      if(!user.value) return []
      return find('users-data', {
        populate: ['image'],
        filters: {
          user: { id: { $eq: id }}
        },
      })
    },
    getProfilePicture:  (item) => {
      const backendUrl = useBackendUrl()
      if(item?.attributes?.image?.data?.attributes?.formats?.medium?.url) return backendUrl + item?.attributes?.image?.data?.attributes?.formats?.medium?.url
      if(item?.attributes?.image?.data?.attributes?.formats?.thumbnail?.url) return backendUrl + item?.attributes?.image?.data?.attributes?.formats?.thumbnail?.url
      if(item?.attributes?.image?.data?.attributes?.url) return backendUrl + item?.attributes?.image?.data?.attributes?.url
    },
    getOrgId: () => {
      const user = useStrapiUser()
      return user.value?.organisation?.id
    },
    getOrgType: () => {
      const user = useStrapiUser()
      return user.value?.organisation?.type?.code
    },
    getOrg: () => {
      const user = useStrapiUser()
      return {
        id: user.value?.organisation?.id,
        type: user.value?.organisation?.type?.code,
      }
    }
  }
}


export const useRefreshToken = createGlobalState(
  () => useStorage(
    'refresh_token', ''
  )
)

export const useIsProducer = () => {
    const user = useStrapiUser()
    if(!user.value) return false
    return user.value.role?.name == 'Produzent'
}
export const useIsAdmin = () => {
    const user = useStrapiUser()
    if(!user.value) return false
    return user.value.role?.name == 'Admin'
}
export const useIsOrganisation = () => {
    const user = useStrapiUser()
    if(!user.value) return false
    return useRoleIsOrganisation(user.value.role?.name)
}
export const useIsParentOrganisation = () => {
    const user = useStrapiUser()
    if(!user.value) return false
    return user.value.role?.name == 'Dachorganisation'
}
export const useIsCertification = () => {
    const user = useStrapiUser()
    if(!user.value) return false
    return user.value.role?.name == 'Zertifikationsstelle'
}
export const useRoleIsProducer = (roleName) => { return roleName == 'Produzent' }
export const useRoleIsOrganisation = (roleName) => { return roleName == 'Regionalmarke' || roleName == 'Dachorganisation' }
export const useRoleIsOrgAndCert = (roleName) => { return roleName == 'Regionalmarke' || roleName == 'Dachorganisation' || roleName == 'Zertifikationsstelle' }
export const useRoleColor = (type) => {
    switch (type) {
      case 'Produzent':
        return 'orange'
      case 'Regionalmarke':
        return 'green'
      case 'regional':
        return 'green'
      case 'Zertifikationsstelle':
        return 'green'
      case 'certification':
        return 'green'
      case 'Dachorganisation':
        return 'green'
      case 'mainbrand':
        return 'green'
      case 'Admin':
        return 'blue'
      default:
        return 'primary'
    }
}

export const useIfIsSignedIn = (callback, defaultValue) => {
    const user = useStrapiUser()
    if(!user.value || !useIsProducer()) return defaultValue
    return callback()
}